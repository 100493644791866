import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/builds/proscom/hse-design/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { ComponentLinks, FigmaEmbed } from '../common';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "sidepane"
    }}>{`SidePane`}</h1>
    <ComponentLinks name={'SidePane'} storybook={'/?path=/story/sidepane-sidepane--playground'} vueStorybook={'/?path=/story/sidepane--playground'} figma={'https://www.figma.com/file/cBHD57QcCt9WDT4e7e2B3w/hse_general_library?node-id=29703%3A37441'} mdxType="ComponentLinks" />
    <h2 {...{
      "id": "описание"
    }}>{`Описание`}</h2>
    <p>{`SidePane – это закреплённая боковая панель, содержащая  контекстные элементы, соответствующие текущему контенту и графически отделённая от`}{` `}{`основной области контента. Размещается на странице слева или справа.`}</p>
    <p>{`Для задания положения на странице используйте проп `}<inlineCode parentName="p">{`position`}</inlineCode>{`. Для расположения иконки закрытия внутри или снаружи используйте проп `}<inlineCode parentName="p">{`outer`}</inlineCode>{`.`}</p>
    <p>{`Пример использования находится в сторибуке. Код использования:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-tsx"
      }}>{`const [collapsed, setCollapsed] = useState(false);

return (<SidePane collapsed={collapsed} onCollapse={setCollapsed} />)
`}</code></pre>
    <FigmaEmbed node='?node-id=29906%3A41507' mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=29906%3A43686' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "поведение"
    }}>{`Поведение`}</h2>
    <FigmaEmbed node='?node-id=30133%3A45875' mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "анатомия"
    }}>{`Анатомия`}</h2>
    <FigmaEmbed node='?node-id=30133%3A45920' height={1200} mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=30169%3A41135' height={1200} mdxType="FigmaEmbed" />
    <FigmaEmbed node='?node-id=30169%3A41319' height={1200} mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "использование"
    }}>{`Использование`}</h2>
    <FigmaEmbed node='?node-id=30133%3A45880' mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      